window.onscroll = function() {
  let scrollButton = document.querySelector('.js-scroll-top');
  
  if(window.scrollY >= 500) {
    scrollButton.style.visibility="visible"
    scrollButton.style.opacity="100%"
  } else {
    scrollButton.style.visibility="hidden";
    scrollButton.style.opacity="0";
  }
};

function scrollTop() {
  window.scrollTo(0,0);
}

function scrollTopEventListener(event, fn) {
  const scrollTopButton = document.querySelector('.js-scroll-top');
  if (scrollTopButton !== null) { scrollTopButton.addEventListener(event, fn, false); }
}

document.addEventListener('DOMContentLoaded', function() {
  scrollTopEventListener('click', scrollTop);
}, false);

document.addEventListener("turbolinks:load", function() {
  scrollTopEventListener('click', scrollTop);
}, false);
