function popoverInitialize() {
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
}

document.addEventListener('DOMContentLoaded', function() {
  popoverInitialize()
}, false);

document.addEventListener("turbolinks:load", function() {
  popoverInitialize()
}, false);
