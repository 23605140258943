import { tns } from "tiny-slider/dist/tiny-slider"

function tinySliderInitialize() {
  const slidelList = document.querySelectorAll('.marketing-banner-slider')
  if (slidelList.length) {
    slidelList.forEach((slider) => {
      let sliderElement = tns({
        container: slider,
        mode: 'gallery',
        items: 1,
        center: true,
        autoplay: true,
        controls: false,
        autoplayButton: false,
        nav: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
          480: {items: 1},
          640: {items: 1},
          1024: {items: 1},
          1200: {items: 1},
          1400: {items: 1}
        }
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', function() {
  tinySliderInitialize()
}, false);

document.addEventListener("turbolinks:load", function() {
  tinySliderInitialize()
}, false);
