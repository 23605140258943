require('turbolinks').start();

import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) { Rails.start(); }

import '@popperjs/core'

require.context('../fonts', true)
require.context('../images', true)

import '@fortawesome/fontawesome-free/css/all.css'
import 'tiny-slider/src/tiny-slider.scss'

window.bootstrap = require('bootstrap/dist/js/bootstrap');

import '../javascripts/serviceworker-companion'
import '../javascripts/closeModal'
import '../javascripts/popover'
import '../javascripts/tiny-slider-init'
import '../javascripts/donate-modal'
import '../javascripts/copy-url'
import '../javascripts/scroll-top'

import '../stylesheets/application.sass'
